import { Heading, useColorModeValue, Text, Img, Center, Divider, Link, Box, Skeleton, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from '../../components/card/Card'
import { formSchemaType, JsonObjectForm } from '../../components/jsonObjectForm/JsonObjectForm'
import { PoweredBy } from '../../components/poweredBy/PoweredBy'
import { convertToTitleCase } from '../../helpers/commonFunctions'

export const CertificateForm = () => {
    const toast = useToast()
    //@ts-ignore
    const { id } = useParams()
    const [jobFormData, setJobFormData] = useState<{
        success: boolean,
        id?: string,
        name?: string,
        formFields?: string[],
        certificateType?: string,
        message?: string,
        eventName?: string
    }>()
    const [formSchema, setFormSchema] = useState<formSchemaType[]>()
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyOTQ4OTA1MSwiZXhwIjoxOTQ1MDY1MDUxfQ.cqzzLnz6Pwt-pWZ2DLy18N5scDme6HYlGhMCLzMv5Po");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow' as RequestRedirect
        };

        const jobData = await fetch(`https://gpnmjenofbfeawopmhkj.functions.supabase.co/post-process/jobs/${id}`, requestOptions)
        const jobDataJson = await jobData.json()
        setJobFormData(jobDataJson)
        setIsLoading(false)
    }

    const submitData = async (data:{[key:string]: any}[]) => {
        
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyOTQ4OTA1MSwiZXhwIjoxOTQ1MDY1MDUxfQ.cqzzLnz6Pwt-pWZ2DLy18N5scDme6HYlGhMCLzMv5Po");

        let formdata = new FormData();
        //@ts-ignore
        formdata.append("certificateData", JSON.stringify(data['certificate_form']));

        var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow' as RequestRedirect
            };

        const submitDataResponse = await fetch(`https://gpnmjenofbfeawopmhkj.functions.supabase.co/post-process/jobs/${id}`, requestOptions)
        const jobDataJson = await submitDataResponse.json()
        if (jobDataJson.success === true) {
            toast({
                title: 'Certificate Created',
                description: "We have created your certificate, You should get the same on your email id in few minutes",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        } else {
            toast({
                title: 'Certificate Creation failed',
                description: "Please try again later, If you feel this issue is persistent please contect us at support@givemycertificate.com",
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }

    const formSchemaDefaults:{
        [key:string]: formSchemaType
    } = {
        name: {
            fieldName: 'Name',
            fieldKey: 'name',
            fieldType: 'text',
            fieldPlaceholder: 'Name',
            fieldIsRequired: true,
            fieldLabel: 'Name',
            fieldValidationRules: {
                required: true,
                minLength: {
                    value: 3,
                    message: 'Value is too short, Enter atleast 3 characters'
                }
            }
        },
        email: {
            fieldName: 'Email',
            fieldKey: 'email',
            fieldType: 'text',
            fieldPlaceholder: 'Email',
            fieldIsRequired: true,
            fieldLabel: 'Email',
            fieldValidationRules: {
                required: true,
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Enter a valid e-mail address',
                },
            }
        }
    }
    
    useEffect(() => {
        if (jobFormData && jobFormData.formFields &&jobFormData?.formFields?.length > 0) {
            const formSchemaValue:formSchemaType[] = jobFormData?.formFields?.map(val => {
                if (formSchemaDefaults[val] !== undefined) {
                    return formSchemaDefaults[val]
                } else {
                    return {
                        fieldName: convertToTitleCase(val),
                        fieldKey: val,
                        fieldType: 'text',
                        fieldPlaceholder: convertToTitleCase(val),
                        fieldIsRequired: true,
                        fieldLabel: convertToTitleCase(val),
                        fieldValidationRules: {
                            required: true,
                            minLength: {
                                value: 3,
                                message: 'Value is too short, Enter atleast 3 characters'
                            }
                        }
                    }
                }
            })
            setFormSchema(formSchemaValue); 
        }
    }, [jobFormData])
    

    useEffect(() => {
        fetchData()
    }, [])

    if ( jobFormData && Object.keys(jobFormData).length > 0 && jobFormData.success === true ) {
        let formValues:{[key:string]: string} = {}
        if (jobFormData && jobFormData.formFields &&jobFormData?.formFields?.length > 0) {
            jobFormData?.formFields?.map(val => {
                formValues[val] = ''
            })
        }
        return (
            <Center>
                <Card w={['90%', null, '60%', '60%']} maxW='4xl'>
                    <Heading textAlign="center" mb="14" mt='5'>
                        {jobFormData.eventName}
                    </Heading>
                    <Skeleton isLoaded={formSchema !== undefined}>
                        {
                            formSchema &&
                            <JsonObjectForm 
                                formName='certificate_form'
                                formSchema={formSchema}
                                formValues={[formValues]}
                                functionToCallOnSubmitForm={submitData}
                                isAddNewValueAllowed={false}
                                showDeleteButton={false}
                            />
                        }
                    </Skeleton>
                </Card>
            </Center>
        )
    }

    if (isLoading) {
		return (
            <Center>
                <Skeleton height="100px" />
            </Center>
        )
	}

    return (
        <Center>
            <Card w={['90%', null, '60%', '60%']}>
                <Center w="100%" h="100%" mb="5">
                    <Img
                        htmlWidth="150px"
                        htmlHeight="150px"
                        height='150px'
                        objectFit="cover"
                        src="https://thumbs.gfycat.com/SpecificSlimIndianringneckparakeet-small.gif"
                        alt="Job Not found"
                    />
                </Center>
                <Divider my={5} />
                <Box textAlign="center">
                    <Heading size="lg" mb="5">
                        Job Not Found
                    </Heading>
                    <Text>
                        We are unable to find any certificate form associated with the provided job id. If you 
                        think its a mistake contact us at {" "}
                        <Link 
                            href="mailto:support@givemycertificate.com"
                        >
                            support@givemycertificate.com
                        </Link>
                    </Text>
                </Box>
                <PoweredBy />
            </Card>
        </Center>
    )
}
