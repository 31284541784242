import { Box, BoxProps, Button, ButtonProps, Checkbox, CheckboxProps, Flex, FormControl, FormErrorMessage, IconButton, Input, InputProps, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputProps, NumberInputStepper, Select, SelectProps, Switch, SwitchProps, TableCaption, TableCellProps, TableProps, TableRowProps } from '@chakra-ui/react'
import React, { forwardRef, useImperativeHandle } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from "../responsiveTable/table"
import { useForm, useFieldArray, RegisterOptions, FormState, UseFormGetValues } from "react-hook-form"
import { FaChevronCircleUp, FaHistory, FaPlusSquare, FaTrash } from 'react-icons/fa'
import "../responsiveTable/wide_styles.css"

export type stringOrNumber = string | number

export interface formSchemaType extends InputProps {
  fieldName: string,
  fieldKey: string,
  fieldType: 'text' | 'number' | 'boolean' | 'select' | 'switch',
  fieldSelectOptions?: stringOrNumber[],
  fieldPlaceholder?: string,
  fieldIsRequired: boolean,
  fieldLabel?: string,
  fieldValidationRules?: RegisterOptions,
  fieldProps?: TableCellProps
}

export interface formValueType {
  [key:string]: string | number | readonly string[] | boolean | undefined
}

export interface JsonObjectFormType extends BoxProps {
    formSchema: formSchemaType[],
    isAddNewValueAllowed?: boolean,
    formValues?: formValueType[],
    formRowProps?: TableRowProps,
    showDeleteButton?: boolean,
    deleteButtonProps?: ButtonProps,
    formCaption?: stringOrNumber | null,
    formTableProps?: TableProps,
    formNumberInputProps?: NumberInputProps,
    formCheckboxProps?: CheckboxProps,
    formSwitchProps?: SwitchProps,
    formSelectProps?: SelectProps,
    deleteButtonCellProps?: TableCellProps,
    functionToCallOnSubmitForm: (arg0: any) => any,
    formName: string,
}

export interface jsonObjectFormRefFunctionType {
  getFormValues: (fieldName?:string) => UseFormGetValues<formSchemaType>,
  formState: FormState<formSchemaType>
}

export const JsonObjectForm = forwardRef(( props:JsonObjectFormType, ref ) => {
  const { 
    formSchema, 
    isAddNewValueAllowed=true, 
    formValues, 
    formRowProps, 
    showDeleteButton=true,
    deleteButtonProps,
    formCaption,
    formTableProps,
    formNumberInputProps,
    formCheckboxProps,
    formSwitchProps,
    formSelectProps,
    deleteButtonCellProps,
    functionToCallOnSubmitForm,
    formName,
    ...restProps 
  } = props
  const { register, control, handleSubmit, reset, formState, getValues } = useForm({
    defaultValues: {
      [formName]: formValues || []
    }
  })
  const { errors, isSubmitting } = formState
  
  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: formName
  });

  useImperativeHandle( ref, () => ({
    getFormValues: (fieldName?:string) => {
        if (fieldName) {
          return getValues(fieldName)
        } else {
          return getValues()
        }
    },
    formState
  }))

  const handleAddNewButtonClick = () => {
    let newFieldObject:formValueType = {}
    formSchema.map( (item) => {
      newFieldObject[item.fieldKey] = ''
      return true
    })
    append(newFieldObject)
  }
  
  const resetFormToDefault = () => {
    reset()
  }

  const onSubmit = async (data:any) => {
    await functionToCallOnSubmitForm(data)
  }

  return (
    <Box {...restProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Table {...formTableProps}>
          {formCaption && <TableCaption>{formCaption}</TableCaption>}
          <Thead>
            <Tr>
              {
                formSchema.map((
                  {
                    fieldType, 
                    fieldPlaceholder, 
                    fieldIsRequired, 
                    fieldKey, 
                    fieldSelectOptions, 
                    fieldName, 
                    fieldLabel,
                    fieldValidationRules,
                    fieldProps,
                    ...restProps
                  }, fieldIndex ) => {
                    return (
                      <Th key={fieldIndex}>
                        { fieldLabel }{ fieldIsRequired ? ' *' : '' }
                      </Th>
                    )
                  }
                )
              }
              {
                showDeleteButton &&
                <Th>
                  Remove
                </Th>
              }
            </Tr>
          </Thead>
          <Tbody>
            {fields.map((item, index) => {
                return (
                  <Tr key={item.id} {...formRowProps}>
                    {
                      formSchema.map((
                        {
                          fieldType, 
                          fieldPlaceholder, 
                          fieldIsRequired, 
                          fieldKey, 
                          fieldSelectOptions, 
                          fieldName, 
                          fieldLabel,
                          fieldValidationRules,
                          fieldProps,
                          ...restProps
                        }, fieldIndex ) => { 
                        switch (fieldType) {
                          case 'text':
                            return (
                              <Td key={fieldIndex} {...fieldProps}>
                                <FormControl
                                  isRequired={fieldIsRequired}
                                  isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                >
                                  <Input 
                                    key={item.id+fieldIndex}
                                    placeholder={fieldPlaceholder} 
                                    type='text' 
                                    isRequired={fieldIsRequired} 
                                    defaultValue={item[fieldKey] as stringOrNumber}
                                    {...register(`${formName}.${index}.${fieldKey}` as const, fieldValidationRules)} 
                                    {...restProps} 
                                  />
                                  <FormErrorMessage>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                </FormControl>
                              </Td>
                            )

                          case 'number':
                            const { size, ...numberRestProps } = restProps
                            return(
                              <Td key={fieldIndex} {...fieldProps}>
                                <FormControl
                                  isRequired={fieldIsRequired}
                                  isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                >
                                  <NumberInput 
                                    key={item.id+fieldIndex}
                                    placeholder={fieldPlaceholder} 
                                    isRequired={fieldIsRequired} 
                                    size={size}
                                    defaultValue={ (item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '' ) ? 0 : ( typeof item[fieldKey] === 'string') ? parseInt(item[fieldKey] as string) : (( typeof item[fieldKey] === 'number') ? item[fieldKey] as number : 0) }
                                    {...formNumberInputProps}
                                  >
                                    <NumberInputField {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)} {...numberRestProps} />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                  <FormErrorMessage>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                </FormControl>
                              </Td>
                            )

                          case 'boolean':
                            return(
                              <Td key={fieldIndex} {...fieldProps}>
                                <FormControl
                                  isRequired={fieldIsRequired}
                                  isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                >
                                  <Checkbox 
                                    key={item.id+fieldIndex}
                                    placeholder={fieldPlaceholder} 
                                    isRequired={fieldIsRequired} 
                                    defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0 || item[fieldKey] === '')}
                                    {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                    {...formCheckboxProps}
                                  />
                                  <FormErrorMessage>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                </FormControl>
                              </Td>
                            )

                          case 'switch':
                            return(
                              <Td key={fieldIndex} {...fieldProps}>
                                <FormControl
                                  isRequired={fieldIsRequired}
                                  isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                >
                                  <Switch 
                                    key={item.id+fieldIndex}
                                    placeholder={fieldPlaceholder} 
                                    isRequired={fieldIsRequired} 
                                    defaultChecked={!(item[fieldKey] === false || typeof item[fieldKey] === 'undefined' || item[fieldKey] === null || item[fieldKey] === 0)}
                                    {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                    {...formSwitchProps}
                                  />
                                  <FormErrorMessage>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                </FormControl>
                              </Td>
                            )

                          case 'select':
                            return(
                              <Td key={fieldIndex} {...fieldProps}>
                                <FormControl
                                  isRequired={fieldIsRequired}
                                  isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                >
                                  <Select 
                                    key={item.id+fieldIndex}
                                    placeholder={fieldPlaceholder} 
                                    isRequired={fieldIsRequired} 
                                    defaultValue={item[fieldKey] as string}
                                    {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)}
                                    {...formSelectProps}
                                  >
                                    {
                                      fieldSelectOptions?.map((val, index) => {
                                        return <option key={index} value={val} >{val}</option>
                                      })
                                    }
                                  </Select>
                                  <FormErrorMessage>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                </FormControl>
                              </Td>
                            )
                        
                          default:
                            return (
                              <Td key={fieldIndex} {...fieldProps}>
                                <FormControl
                                  isRequired={fieldIsRequired}
                                  isInvalid={(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? true : false}
                                >
                                  <Input 
                                    key={item.id+fieldIndex}
                                    placeholder={fieldPlaceholder} 
                                    type='text' 
                                    isRequired={fieldIsRequired} 
                                    defaultValue={item[fieldKey] as stringOrNumber}
                                    {...register(`${formName}.${index}.${fieldKey}`, fieldValidationRules)} 
                                    {...restProps} 
                                  />
                                  <FormErrorMessage>{(errors && errors[formName] && errors[formName]![index] && errors[formName]![index]![fieldKey]) ? errors[formName]![index]![fieldKey]?.message : null }</FormErrorMessage>
                                </FormControl>
                              </Td>
                            )
                        }
                      })
                    }
                    {
                      showDeleteButton &&
                      <Td {...deleteButtonCellProps}>
                        <IconButton
                          aria-label='Remove Field'
                          icon={<FaTrash />}
                          colorScheme='red'
                          variant='outline'
                          onClick={() => remove(index)}
                          {...deleteButtonProps}
                        />
                      </Td>
                    }
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
        
        <Flex justifyContent='center' direction={['column', 'row']}>
          <Button
            mt="5"
            type='submit'
            colorScheme="blue"
            minW="fit-content"
            maxW="xl"
            leftIcon={<FaChevronCircleUp />}
            isLoading={isSubmitting}
            loadingText='Saving...'
          >
            Generate Certificate
          </Button>
          <Box mx='3' />
          <Button
            mt="5"
            colorScheme="red"
            minW="fit-content"
            maxW="xl"
            leftIcon={<FaHistory />}
            onClick={resetFormToDefault}
          >
            Clear Fields
          </Button>
        </Flex>
      </form>
      {
        isAddNewValueAllowed && 
        <Flex
            alignItems="center"
            justifyContent="center"
        >
            <Button
                mt="5"
                colorScheme="blue"
                minW="fit-content"
                w="90%"
                maxW="2xl"
                leftIcon={<FaPlusSquare/>}
                onClick={handleAddNewButtonClick}
            >
                Add New Certificate Data
            </Button>
        </Flex>
      }
    </Box>
  )
})
